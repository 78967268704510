<template>
	<!-- 报检汇总 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">报验汇总</div>
			<div class="line"></div>
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="19" style="margin-left:20px">
						<!--					<el-form-item label="标的物">-->
						<!--						<el-select class="selItemInput" v-model="subjectNo" placeholder="请选择">-->
						<!--							<el-option-->
						<!--							  v-for="item in subjectList"-->
						<!--							  :key="item.displayValue"-->
						<!--							  :label="item.displayName"-->
						<!--							  :value="item.displayValue">-->
						<!--							</el-option>-->
						<!--						</el-select>-->
						<!--					</el-form-item>-->
						<el-form-item label="检查时间" class="selFormItem" style="margin-top:2px">
							<el-date-picker class="selDateInput" v-model="testTime" type="daterange" range-separator="~"
								value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small"
							@click="selGetDataList()">查询</el-button>
						<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
			<div class="line" style="margin-top:-2px"></div>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; " body-style="height: 100%; padding: 0;">
				<div v-for="(data, index) in dataList">
					<el-row :span="24" class="sub_row">
						 {{data.subjectNo }}</el-row>
					<el-table class="dataListTable" :row-class-name="tableRowClassName" :data="data.records" header-align="center"
						height="100%" default-expand-all style="width: 100%;" row-key="id" border
						header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
						<el-table-column prop="majorName" label="专业" align="center">
						</el-table-column>
						<el-table-column prop="testItemCount" label="检验（项）" align="center">
						</el-table-column>
						<el-table-column prop="acceptCount" label="接受（项） " align="center">
						</el-table-column>
						<el-table-column prop="acceptWhereCount" label="有条件接受（项） " align="center">
						</el-table-column>
						<el-table-column prop="recheckAfterCorrectionCount" label="修正后复检（项） " align="center">
						</el-table-column>
						<el-table-column prop="refuseCount" label="不接受（项）" align="center">
						</el-table-column>
						<el-table-column label="报检合格率" align="center">
							<template slot-scope="scope">
								{{ scope.row.countRate }}%
							</template>
						</el-table-column>
					</el-table>
				</div>
			</el-card>
		</div>
		<!-- 弹窗, 新增节点 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="options"></add-or-update>
	</div>
</template>
<script>
import AddOrUpdate from './testItem-add-or-update'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			form: {},
			addOrUpdateVisible: false,
			testTime: [],
			subjectNo: $common.getItem("subjectNo"),
			category: '',
			dataList: [],
			subjectList: []
		};
	},
	components: {
		AddOrUpdate
	},
	mounted() {
		this.category = this.$route.query.type;
		this.getDataList();
		// this.getSubjectDropDown();
	},
	methods: {
		// 新增检验项目
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id)
			})
		},
		getRowClass(row, rowIndex) {
			if (!row.row.subList || row.row.subList.length === 0) {
				return 'row-expand-cover'
			}
			return ''
		},
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 150;
			return { height: height + 'px' }
		},
		handleSelectionChange(val) {
			this.tableSelVal = val;
		},
		selGetDataList() {
			this.page = 1;
			this.getDataList();
		},
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/testrecord/listSummary",
				method: "get",
				params: {
					workNo: $common.getItem("workNo"),
					subjectNo: this.subjectNo,
					testDateBegin: this.testTime[0] ? this.testTime[0] : "",
					testDateEnd: this.testTime[1] ? this.testTime[1] : "",
					category: this.category,
					limit: this.limit,
					page: this.page
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body;
				}
			});
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
				}
			});
		},
		resetFormData() {
			this.subjectNo = "";
			this.testTime = [];
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

.selDateInput {
	width: 280px !important;
	margin-top: 2px;
	height: 32px;

	::v-deep .el-input__inner {
		height: 33px;
	}
}

::v-deep(.selDateInput .el-range-separator) {
	width: 40px !important;
	margin-top: -8px;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}

.sub_row {
	font-weight: bolder;
	font-size: 16px;
	margin: 15px;
}
</style>
